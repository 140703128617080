import { SERVER_URL } from './config';

const contentAPI = {

    getPageContent: (slug, lang = 'ru') => {
        return fetch(SERVER_URL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                query: `
                  {
                    pages(where: {language: ${ lang.toUpperCase() }, name: ""}) {
                      edges {
                        node {
                          id
                          blocks {
                            blocks {
                              ... on Page_Blocks_Blocks_Partners {
                                description
                                fieldGroupName
                                title
                                logos {
                                  guid
                                }
                              }
                              ... on Page_Blocks_Blocks_ReviewsBlock {
                                fieldGroupName
                                title
                                reviews {
                                  text
                                  position
                                  name
                                  logo {
                                    guid
                                  }
                                  fieldGroupName
                                  avatar {
                                    guid
                                  }
                                }
                              }
                              ... on Page_Blocks_Blocks_OurProjects {
                                description
                                fieldGroupName
                                title
                              }
                              ... on Page_Blocks_Blocks_ServicesBlock {
                                fieldGroupName
                                bigImage {
                                    guid
                                }
                                services {
                                    number
                                    title
                                    description
                                    service {
                                      ... on Service {
                                        id
                                        slug
                                      }
                                    }
                                }
                              }
                              ... on Page_Blocks_Blocks_GoodTitle {
                                fieldGroupName
                                title1
                                title2
                              }
                              ... on Page_Blocks_Blocks_NumbersBlock {
                                fieldGroupName
                                numbers {
                                  numberBefore
                                  numberNumber
                                  numberAfter
                                  numberStep
                                  title
                                  number
                                  fieldGroupName
                                  description
                                }
                              }
                              ... on Page_Blocks_Blocks_AboutUs {
                                content
                                fieldGroupName
                                title
                              }
                              ... on Page_Blocks_Blocks_MainBlock {
                                description
                                fieldGroupName
                                videoTextImage {
                                  guid
                                }
                                videoImage {
                                  guid
                                }
                                video {
                                  guid
                                }
                                title4
                                title3
                                title2
                                title1
                                videoIframe
                                videoEmbed
                              }
                              ... on Page_Blocks_Blocks_Description {
                                  description
                                  fieldGroupName
                                  text {
                                    color
                                    text
                                  }
                              }
                            }
                          }
                          seo {
                              metaDesc
                              title
                          }
                        }
                      }
                    }
                  }
                `,
                }),
            })
            .then((res) => {return res.json()})
            .catch((res) => {console.log(res.data)})
    },



    getPortfolio: (lang = 'ru') => {
        return fetch(SERVER_URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
            query: `
            query MyQuery {
                portfolio (where: {offsetPagination: { size: 100 }, language: ${ lang.toUpperCase() }}){
                    edges {
                          node {
                            id
                            slug
                            excerpt
                            featuredImage {
                              node {
                                mediaItemUrl
                              }
                            }
                            title
                            date
                          }
                        }
                    }
                }`,
            }),
        })
        .then((res) => {return res.json()})
        .catch((res) => {console.log(res.data)})
    },




        getPortfolioProject: (slug, lang = 'ru') => {
            return fetch(SERVER_URL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                query: `
                    query MyQuery {
                      portfolio(where: {name: "${slug}"}) {
                        edges {
                          node {
                            title
                            id
                            slug
                            excerpt
                            title
                            date
                            seo {
                                metaDesc
                                title
                            }
                            featuredImage {
                              node {
                                mediaItemUrl
                                sourceUrl
                              }
                            }
                            portfolioContent {
                              content {
                                ... on Project_Portfoliocontent_Content_Text {
                                  content
                                  fieldGroupName
                                  title
                                }
                                ... on Project_Portfoliocontent_Content_BigImage {
                                  fieldGroupName
                                  image {
                                    sourceUrl
                                  }
                                }
                                ... on Project_Portfoliocontent_Content_Gallery {
                                  fieldGroupName
                                  gallery {
                                    sourceUrl
                                  }
                                }
                                ... on Project_Portfoliocontent_Content_Video {
                                  fieldGroupName
                                  video
                                  embed
                                }
                                ... on Project_Portfoliocontent_Content_BigText {
                                  fieldGroupName
                                  text
                                }
                                ... on Project_Portfoliocontent_Content_Review {
                                  fieldGroupName
                                  name
                                  title
                                  text
                                  position
                                  photo {
                                    sourceUrl
                                  }
                                  logo {
                                    sourceUrl
                                  }
                                }
                                ... on Project_Portfoliocontent_Content_NextProject {
                                  fieldGroupName
                                  topText
                                  link {
                                    ... on Project {
                                      slug
                                    }
                                  }
                                  title
                                  image {
                                      sourceUrl
                                  }

                                }
                              }
                              mainImage {
                                sourceUrl
                              }
                            }
                          }
                        }
                      }
                    }`,
                }),
            })
            .then((res) => {return res.json()})
            .catch((res) => {console.log(res.data)})
        },




        getSingleService: (slug, lang = 'ru') => {
            return fetch(SERVER_URL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                query: `
                    query MyQuery {
                      services(where: {name: "${slug}"}) {
                        edges {
                          node {
                            title
                            id
                            slug
                            excerpt
                            title
                            date
                            seo {
                                metaDesc
                                title
                            }
                            featuredImage {
                              node {
                                mediaItemUrl
                                sourceUrl
                              }
                            }
                            portfolioContent {
                              content {
                                ... on Service_Portfoliocontent_Content_Text {
                                  content
                                  fieldGroupName
                                  title
                                }
                                ... on Service_Portfoliocontent_Content_BigImage {
                                  fieldGroupName
                                  image {
                                    sourceUrl
                                  }
                                }
                                ... on Service_Portfoliocontent_Content_Gallery {
                                  fieldGroupName
                                  gallery {
                                    sourceUrl
                                  }
                                }
                                ... on Service_Portfoliocontent_Content_Video {
                                  fieldGroupName
                                  video
                                  embed
                                }
                                ... on Service_Portfoliocontent_Content_BigText {
                                  fieldGroupName
                                  text
                                }
                                ... on Service_Portfoliocontent_Content_Review {
                                  fieldGroupName
                                  name
                                  title
                                  text
                                  position
                                  photo {
                                    sourceUrl
                                  }
                                  logo {
                                    sourceUrl
                                  }
                                }
                                ... on Service_Portfoliocontent_Content_NextProject {
                                  fieldGroupName
                                  topText
                                  link {
                                    ... on Project {
                                      slug
                                    }
                                  }
                                  title
                                  image {
                                      sourceUrl
                                  }

                                }
                              }
                              mainImage {
                                sourceUrl
                              }
                            }
                          }
                        }
                      }
                    }`,
                }),
            })
            .then((res) => {return res.json()})
            .catch((res) => {console.log(res.data)})
        },


        themeSettings: () => {
            return fetch(SERVER_URL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                query: `
                query MyQuery {
                    themeSettings {
                        themeSettings {
                            facebookLink
                            instagramLink
                            linkedinLink
                            youtubeLink
                            telegramLink
                            whatsappLink
                        }
                    }
                }`,
                }),
            })
            .then((res) => {return res.json()})
            .catch((res) => {console.log(res.data)})
        },


}

export default contentAPI
