// import ReactFullpage from '@fullpage/react-fullpage';
// import { FullPage, Slide } from 'react-full-page';
// // import Fullpage, { Sections, Section } from '@ap.cx/react-fullpage'
// import ScrollableContainer from "react-full-page-scroll";
// import {SectionsContainer, Section} from 'react-fullpage';
// import ReactPageScroller from 'react-page-scroller';
//
// import "fullpage.js/vendors/scrolloverflow"; // Optional. When using scrollOverflow:true
// import ReactFullpage from "@fullpage/react-fullpage";
import { Component, Fragment } from 'react'
import { withRouter } from "react-router";
import contentAPI from '../../api/contentAPI'
import {Helmet} from "react-helmet";


import CaseBlock from '../blocks/CaseBlock'


class Case extends Component {


    state = {
        content:[],
        main_block:{
            title:'',
            image_url:'',
            content:'',
        },
        mainImage:{
            sourceUrl:''
        },
        seo:{
            title:'Cheftech',
            metaDesc:'Cheftech',
        }
    }

    recordEdge = (edge) => {
        const main_block = {
            title:edge.node.title,
            image_url:edge.node.featuredImage ? edge.node.featuredImage.node.sourceUrl : '',
            content:edge.node.content,
        }
        const { content, mainImage } = edge.node.portfolioContent
        const { seo } = edge.node
        this.setState({ content, mainImage, main_block, seo })
    }

    componentDidMount = () => {
        let { slug, lang } = this.props.match.params
        if( !lang ){
            lang = 'ru'
        }
        contentAPI.getPortfolioProject(slug, lang).then((data) => {

            if( data.data.portfolio.edges.length ){
                this.recordEdge(data.data.portfolio.edges[0])
            } else{
                contentAPI.getSingleService(slug, lang).then((data) => {

                    if( data.data.services.edges.length ){
                        this.recordEdge(data.data.services.edges[0])
                    } else{
                        this.setState({ content:[], mainImage:'', main_block:{}, seo:{} })
                    }

                })
            }
            this.props.onLoad()

        })
    }

    render(){

        const { content, main_block, mainImage, seo } = this.state

        return (
            <div class="portfolio_page">

                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{ seo.title }</title>
                    <meta name="description" content={ seo.metaDesc } />
                </Helmet>

                <section className="page_title_section">
                    <div className="container-fluid">
                        <div className="page_title_block">
                            <h1 className="page_title">{ main_block.title }</h1>
                            { mainImage && mainImage.sourceUrl ? (
                                <div className="page_title_image_block">
                                    <img className="w-100" src={ mainImage.sourceUrl } />
                                    <div className="page_title_image_arrow"></div>
                                </div>
                            ) : '' }
                        </div>
                    </div>
                </section>

            { content.length ? content.map((block) => (
                <Fragment>
                    { ['Project_Portfoliocontent_Content_Text', 'Service_Portfoliocontent_Content_Text'].includes(block.fieldGroupName) ? (

                        <section className="white_bg page_content_section">
                            <div className="container-fluid">
                                <div className="page_content">
                                    { block.title ? (
                                        <div className="page_content_title">
                                            <h2>{ block.title }</h2>
                                        </div>
                                    ) : ''}
                                    <div className="page_content_content" dangerouslySetInnerHTML={{ __html:block.content}}></div>
                                </div>
                            </div>
                        </section>

                    ) : ['Project_Portfoliocontent_Content_BigImage', 'Service_Portfoliocontent_Content_BigImage'].includes(block.fieldGroupName) ? (

                        <section className="page_image_section">
                            { block.image && block.image.sourceUrl ? (
                                <img src={ block.image.sourceUrl } />
                            ) : ''}
                        </section>
                    ) : ['Project_Portfoliocontent_Content_Gallery', 'Service_Portfoliocontent_Content_Gallery'].includes(block.fieldGroupName) ? (


                        <section className="white_bg page_images_section">
                            <div className="container-fluid">
                                <div className="page_images">
                                    <div className="row">
                                    { block.gallery.map(({ sourceUrl }) =>(
                                        <div className="col-md-6 col-12 mb-4">
                                            <img src={sourceUrl} className="w-100" />
                                        </div>
                                    )) }
                                    </div>
                                </div>
                            </div>
                        </section>

                    ) : ['Project_Portfoliocontent_Content_Video', 'Service_Portfoliocontent_Content_Video'].includes(block.fieldGroupName) ? (

                        <section className="white_bg page_video_section">
                            <div className="container-fluid">
                                <div dangerouslySetInnerHTML={{ __html:block.video}}></div>

                            </div>
                        </section>

                    ) : ['Project_Portfoliocontent_Content_BigText', 'Service_Portfoliocontent_Content_BigText'].includes(block.fieldGroupName) ? (

                        <section className="white_bg page_content_section">
                            <div className="container-fluid">
                                <div className="page_content">
                                    <div className="page_content_title">
                                        <h3>{ block.text }</h3>
                                    </div>
                                </div>
                            </div>
                        </section>

                    ) : ['Project_Portfoliocontent_Content_Review', 'Service_Portfoliocontent_Content_Review'].includes(block.fieldGroupName) ? (

                        <section className="white_bg page_review_section">
                            <div className="container">
                                <div className="page_content">
                                    <div className="page_content_title text-center">
                                        <h2>{ block.title }</h2>
                                    </div>
                                </div>
                                <div className="page_review">
                                    <div className="row">
                                        <div className="col-9">
                                            <div className="review_text">
                                                <div dangerouslySetInnerHTML={{ __html:block.text}}></div>
                                            </div>
                                            <div className="review_author d-flex">
                                                <div className="review_author_image_block mr-3">
                                                    { block.photo ? (
                                                        <div className="review_author_image" style={{ backgroundImage: "url('" + block.photo.sourceUrl  + "')" }}></div>
                                                    ) : ''}
                                                </div>
                                                <div className="review_author_content">
                                                    <div className="review_author_title">{ block.name }</div>
                                                    <div className="review_author_description">{ block.position }</div>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="col-3 volvo">
                                            { block.logo ? (
                                            <img src={ block.logo.sourceUrl } className="w-100" />
                                            ) : ''}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                    ) : ['Project_Portfoliocontent_Content_NextProject', 'Service_Portfoliocontent_Content_NextProject'].includes(block.fieldGroupName) ? (

                        <section className="page_next_case">
                            <div className="container">
                                <div className="next_case_title">
                                    <div className="next_case_title_top">
                                        { block.topText }
                                    </div>
                                    <h2>{ block.title }</h2>
                                </div>
                                <a href={ block.link ? `/${block.link.slug}` : '#' } className="next_project_link">
                                    <div className="next_case_image">
                                        <div className="next_case_image_image">
                                            { block.image ? (
                                                <img src={ block.image.sourceUrl } className="w-100" />
                                            ) : ''}
                                        </div>
                                        <div className="next_case_image_button">
                                            <img src="/images/next_case_button.svg" />
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </section>

                    ) : ''}
                </Fragment>
            )) : '' }




            </div>
        );
    }
}

export default withRouter(Case);
