import React, { Component } from 'react'

export default class MainBlock extends Component {

    state = {
    }


    componentDidMount = () => {

    }

    render(){

        const { content } = this.props

        return(
            <section className="white_bg" id="contacts">
                <div className="container-fluid footer_title_block">
                    <h2 className="footer_title">
                        { content.text ? content.text.map(( word ) => (
                            <span className={ word.color }>{ word.text }</span>
                        )) : '' }
                    </h2>
                    <div className="row">
                        <div className="description" dangerouslySetInnerHTML={{ __html: content.description }}></div>
                    </div>
                </div>
            </section>
        )
    }
}
