
const images_urls = [
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00000-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00001-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00002-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00003-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00004-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00005-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00006-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00007-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00008-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00009-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00010-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00011-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00012-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00013-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00014-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00015-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00016-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00017-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00018-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00019-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00020-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00021-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00022-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00023-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00024-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00025-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00026-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00027-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00028-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00029-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00030-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00031-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00032-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00033-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00034-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00035-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00036-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00037-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00038-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00039-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00040-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00041-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00042-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00043-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00044-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00045-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00046-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00047-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00048-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00049-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00050-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00051-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00052-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00053-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00054-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00055-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00056-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00057-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00058-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00059-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00060-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00061-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00062-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00063-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00064-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00065-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00066-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00067-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00068-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00069-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00070-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00071-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00072-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00073-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00074-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00075-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00076-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00077-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00078-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00079-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00080-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00081-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00082-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00083-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00084-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00085-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00086-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00087-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00088-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00089-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00090-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00091-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00092-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00093-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00094-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00095-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00096-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00097-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00098-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00099-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00100-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00101-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00102-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00103-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00104-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00105-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00106-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00107-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00108-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00109-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00110-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00111-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00112-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00113-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00114-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00115-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00116-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00117-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00118-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00119-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00120-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00121-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00122-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00123-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00124-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00125-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00126-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00127-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00128-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00129-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00130-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00131-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00132-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00133-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00134-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00135-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00136-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00137-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00138-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00139-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00140-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00141-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00142-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00143-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00144-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00145-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00146-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00147-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00148-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00149-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00150-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00151-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00152-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00153-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00154-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00155-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00156-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00157-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00158-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00159-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00160-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00161-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00162-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00163-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00164-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00165-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00166-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00167-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00168-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00169-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00170-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00171-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00172-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00173-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00174-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00175-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00176-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00177-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00178-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00179-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00180-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00181-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00182-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00183-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00184-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00185-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00186-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00187-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00188-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00189-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00190-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00191-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00192-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00193-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00194-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00195-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00196-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00197-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00198-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00199-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00200-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00201-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00202-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00203-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00204-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00205-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00206-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00207-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00208-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00209-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00210-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00211-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00212-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00213-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00214-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00215-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00216-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00217-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00218-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00219-min.jpg',
    'https://wp.cheftech.ru/wp-content/uploads/kitchen/kitchen_00220-min.jpg',
]

export {
    images_urls
}
