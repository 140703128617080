import { useEffect, useRef, useState, Fragment } from 'react';
import { ContentsWrapper, getWeightByProgress, useScrollProgress } from 'framed-animation-on-scroll';
import { images_urls } from './ServicesBlockImages';
import Trans from '../../helpers/locale/transStr'
import { withRouter } from "react-router-dom";
import StickyBox from "react-sticky-box";
// import { motion, useViewportScroll, useTransform } from "framer-motion";

function ServicesBlock({ content, location }) {
  const ref = useRef();



  const [progress] = useScrollProgress(ref, 0);

  // const [progress, setProgress] = useState(1);
  const [imageNumber, setImageNumber] = useState(0);
  const [serviceNumber, setServiceNumber] = useState(1);
  const [blockHeight, setBlockHeight] = useState('350vh');
  const [totalHeight, setTotalHeight] = useState(1500);
  const [blocksMiddles, setBlocksMiddles] = useState([]);
  const [isMobile, setIsMobile] = useState(false);

  const { pathname } =  location
  let lang = 'ru'
  if( pathname.split('/')[1] == 'en' ){
      lang = 'en'
  }

  const textImageNumber = {
      0:0,
      1:40,
      2:85,
      3:130,
      4:170,
      5:220,
  }

    const countImageNumber = (scrolledPX) => {
        const blockHeightNumber = totalHeight //parseFloat(blockHeight.replace('px', ''))
        // const scrolledPX = progress * blockHeightNumber / 100 - window.innerHeight * 0.5
        let service_n = 0
        let imageNumber = 0;
        let scrolledInBlock = 0;
        let scrolledInBlockPercent = 0;
        let oneBlockHeight = 0;
        let blocksMiddles = countMiddles()
        if( scrolledPX > blocksMiddles[blocksMiddles.length-1] ){
            service_n = blocksMiddles.length-1
        } else{
            for (var n = 0; n <= blocksMiddles.length; n++) {
                if( blocksMiddles[n] > scrolledPX ){
                    service_n = n;
                    break;
                }
            }
        }

        if( service_n ){
            scrolledInBlock = scrolledPX - blocksMiddles[service_n-1]
            oneBlockHeight = blocksMiddles[n] - blocksMiddles[service_n-1]
        } else{
            scrolledInBlock = scrolledPX // + window.innerHeight * 0.5
            oneBlockHeight = blocksMiddles[0]
        }
        if( scrolledInBlock > 0 ){
            scrolledInBlockPercent = scrolledInBlock / oneBlockHeight
            if( scrolledPX > blocksMiddles[blocksMiddles.length-1] ){
                imageNumber = 220
            } else {
                imageNumber = textImageNumber[service_n] + scrolledInBlockPercent * (textImageNumber[service_n+1] - textImageNumber[service_n]);
            }
        } else if( scrolledPX > blocksMiddles[blocksMiddles.length-1] ){
            imageNumber = 220
        }

        setServiceNumber( service_n );
        imageNumber = Math.min(Math.max( Math.floor( imageNumber ), 1), 220)
        setImageNumber( ('00000' + imageNumber).slice(-5) );
        return imageNumber;
    }



    useEffect(() => () => {
        if( !isMobile ){
            if(progress > 0 && progress <= 100){
                document.getElementById("product_texts").style.top = - 350 * progress / 100 + 'vh'
            } else{
                document.getElementById("product_texts_block").style.position = 'relative'
            }
            const blockHeightNumber = totalHeight //parseFloat(blockHeight.replace('px', ''))
            const scrolledPX = progress * blockHeightNumber / 100 - window.innerHeight * 0.5
            countImageNumber(scrolledPX)
        }
    }, [progress])


    const scrollMobile = () => {
        const block = document.getElementById('machine_block_link')
        // const topScrolled = -block.getBoundingClientRect().top + window.innerHeight / 2 //window.pageYOffset + block.getBoundingClientRect().top - window.innerHeight / 2;
        // if( topScrolled > window.innerHeight / 2){
        const topScrolled = -block.getBoundingClientRect().top + window.innerHeight / 2 //window.pageYOffset + block.getBoundingClientRect().top - window.innerHeight / 2;

        const bottomScrolled = document.getElementById('machine_block').offsetHeight + block.getBoundingClientRect().top;
        if( topScrolled > window.innerHeight / 2 && bottomScrolled > 0 ){
            document.getElementById('machine_block').classList.add('sticky');
            countImageNumber(topScrolled)
        } else {
            setImageNumber( '00040' )
            document.getElementById('machine_block').classList.remove('sticky');
        }
    }

    const defineDeviceSize = () => {
        setIsMobile( window.innerWidth < 768 )
        if( window.innerWidth < 768 ){
            window.addEventListener('scroll', scrollMobile, true);
        } else {
            window.removeEventListener('scroll', scrollMobile, true);
        }

    }

    const countMiddles = () => {
        let middleHeight = 0
        let middles = [];
        for (var i = 0; i < document.querySelectorAll('#product_texts .one_product_text').length; i++) {
            let div = [...document.querySelectorAll('#product_texts .one_product_text')][i]
            let middle_n = 0
            middleHeight += div.offsetHeight
            middles.push( middleHeight )
        }
        middles.push( middleHeight + window.innerHeight / 2 )
        setBlocksMiddles(middles);
        return middles;
    }

    useEffect(() => {
        countMiddles()
    }, [content])

    useEffect(() => {
        let totalHeight = window.innerHeight * 2;
        if( window.innerWidth < 768 ){
            totalHeight = window.innerHeight / 2;
            setIsMobile(true)
        }
        [...document.querySelectorAll('.one_product_text')].map((div) => {
            totalHeight += div.offsetHeight
        })
        setBlockHeight(totalHeight + 40 + 'px')
        setTotalHeight(totalHeight + 40)
        countMiddles()
        defineDeviceSize()
        window.addEventListener('resize', () => {
            defineDeviceSize()
        }, true);
    }, [])


  const { services } = content



  return (

      <Fragment>
          <div id="machine_block_link"></div>

          <ContentsWrapper
            ref={ref}
            height={blockHeight}
            bgColor="#111111"
          >

              <section className="product_slider_section animation_sticky_bg" id="machine_block">
                  <div className="container-fluid animation_sticky">
                      <div className="d-lg-flex d-block">
                          <div className={`product_slider_image_block ${ isMobile ? 'mobile' : '' }`} id="product_slider_image_block">
                            { images_urls.map((image_url, i) => (
                                <Fragment>
                                    <div className="product_slider_image" style={{  display:`${ i==imageNumber ? 'block' : 'none' }` }}>
                                        <img className="product_slider_image_image" src={image_url} />
                                    </div>
                                </Fragment>
                            )) }
                          </div>

                          <div className="product_texts_block" id="product_texts_block">
                              <div className="product_texts" id="product_texts">
                                  { services.length ? services.map((service, i) => (
                                    <div className={`one_product_text active `}>{/*serviceNumber == i || window.innerWidth < 768 ? 'active' : ''*/}
                                        <div className="product_text_number">{ service.number }</div>
                                        <div className="product_text_title">{ service.title }</div>
                                        <div className="product_text_description" dangerouslySetInnerHTML={{ __html: service.description }}></div>
                                        { service.service ? (
                                            <div className="product_button">
                                                <a href={ lang == 'ru' ? `/${service.service.slug}/` : `/${lang}/${service.service.slug}/` }>
                                                    <button><Trans>Подробнее</Trans></button>
                                                </a>
                                            </div>
                                        ) : ''}
                                    </div>
                                  )) : ''}
                              </div>
                          </div>
                      </div>
                  </div>
              </section>
          </ContentsWrapper>
      </Fragment>
  );
}

export default withRouter(ServicesBlock);
