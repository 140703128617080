const strings = {
  "Смотреть портфолио": {
    en: "Watch portfolio",
    ru: "Смотреть портфолио",
  },
  "Подробнее": {
    en: "Read more",
    ru: "Подробнее",
  },
  "Смотреть проект": {
    en: "Watch project",
    ru: "Смотреть проект",
  },
};

export default strings;
