import React, { Component } from 'react'
import { Link } from "react-router-dom";

export default class Header extends Component {

    state = {
        menu:[
            {
                title:'О компании',
                link:'#about_us'
            },
            {
                title:'Услуги',
                link:'#machine_block_link'
            },
            {
                title:'Портфолио',
                link:'#projects_section'
            },
            {
                title:'Поставщики',
                link:'#partners'
            },
            {
                title:'Контакты',
                link:'#contacts'
            },
        ]
    }

    close = () => {
        this.props.closeMenu()
    }

    componentDidMount = () => {

    }

    render(){
        const { open, socialLinks } = this.props
        const { menu } = this.state
        let itemNumber = 0

        return(
            <div className={`menu ${open ? 'active' : ''}`}>


                <div className="menu__container">

                    <div className="menu__hero">
                        <div className="menu__title">
                            {menu.map(({ title, link }) => {
                                return (
                                    <div className="menu__title-item">
                                        <span>
                                            <a href={`/${link}`} id={ 'menu_link_' + link.replace('#', '')} onClick={() => { this.close() }} >{ title }</a>
                                        </span>
                                    </div>
                                )})}
                        </div>

                    </div>



                    <div className="menu__line">
                        <svg width="100%" height="1" viewBox="0 0 1820 1" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <line opacity="0.2" x1="-4.37114e-08" y1="0.5" x2="1820" y2="0.499841" stroke="white"/>
                        </svg>

                    </div>
                    <div className="menu__footer">
                        <ul className="menu__footer-list menu__list">
                            { socialLinks.telegramLink ? (
                                <li className="menu__footer-list-item menu__list-item">
                                <a href={socialLinks.telegramLink}>Telegram <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.32419 0.423058H10.1236C10.3956 0.423058 10.5769 0.609366 10.5769 0.88883V7.87542C10.5769 8.15489 10.3956 8.34119 10.1236 8.34119C9.85167 8.34119 9.67035 8.15489 9.67035 7.87542V2.00669L1.05771 10.5769L0.423096 9.92482L9.03573 1.44776H3.32419C3.05222 1.3546 2.8709 1.16829 2.8709 0.88883C2.8709 0.609366 3.05222 0.423058 3.32419 0.423058Z" fill="white"/>
                                </svg>
                                </a>
                                </li>
                            ) : '' }
                            { socialLinks.viberLink ? (
                                <li className="menu__footer-list-item menu__list-item">
                                <a href={socialLinks.viberLink}>Viber <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.32419 0.423058H10.1236C10.3956 0.423058 10.5769 0.609366 10.5769 0.88883V7.87542C10.5769 8.15489 10.3956 8.34119 10.1236 8.34119C9.85167 8.34119 9.67035 8.15489 9.67035 7.87542V2.00669L1.05771 10.5769L0.423096 9.92482L9.03573 1.44776H3.32419C3.05222 1.3546 2.8709 1.16829 2.8709 0.88883C2.8709 0.609366 3.05222 0.423058 3.32419 0.423058Z" fill="white"/>
                                </svg>
                                </a>
                                </li>
                            ) : '' }
                            { socialLinks.whatsappLink ? (
                                <li className="menu__footer-list-item menu__list-item">
                                <a href={socialLinks.whatsappLink}>Whatsapp <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.32419 0.423058H10.1236C10.3956 0.423058 10.5769 0.609366 10.5769 0.88883V7.87542C10.5769 8.15489 10.3956 8.34119 10.1236 8.34119C9.85167 8.34119 9.67035 8.15489 9.67035 7.87542V2.00669L1.05771 10.5769L0.423096 9.92482L9.03573 1.44776H3.32419C3.05222 1.3546 2.8709 1.16829 2.8709 0.88883C2.8709 0.609366 3.05222 0.423058 3.32419 0.423058Z" fill="white"/>
                                </svg>
                                </a>
                                </li>
                            ) : '' }
                            { socialLinks.youtubeLink ? (
                                <li className="menu__footer-list-item menu__list-item menu__footer-list-info">
                                <a href={socialLinks.youtubeLink}>Youtube <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.32419 0.423058H10.1236C10.3956 0.423058 10.5769 0.609366 10.5769 0.88883V7.87542C10.5769 8.15489 10.3956 8.34119 10.1236 8.34119C9.85167 8.34119 9.67035 8.15489 9.67035 7.87542V2.00669L1.05771 10.5769L0.423096 9.92482L9.03573 1.44776H3.32419C3.05222 1.3546 2.8709 1.16829 2.8709 0.88883C2.8709 0.609366 3.05222 0.423058 3.32419 0.423058Z" fill="white"/>
                                </svg>
                                </a>
                                </li>
                            ) : '' }
                            <li className="menu__footer-list-item menu__list-item menu__list-number">+7 915 399 11 17 <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M3.32419 0.423058H10.1236C10.3956 0.423058 10.5769 0.609366 10.5769 0.88883V7.87542C10.5769 8.15489 10.3956 8.34119 10.1236 8.34119C9.85167 8.34119 9.67035 8.15489 9.67035 7.87542V2.00669L1.05771 10.5769L0.423096 9.92482L9.03573 1.44776H3.32419C3.05222 1.3546 2.8709 1.16829 2.8709 0.88883C2.8709 0.609366 3.05222 0.423058 3.32419 0.423058Z" fill="white"/>
                            </svg>
                            </li>
                        </ul>

                    </div>
                </div>
            </div>
        )
    }
}
