import React, { Component } from 'react'
import Slider from "react-slick";

export default class PartnersBlock extends Component {

    render(){
        const settings = {
    		infinite: true,
    		slidesToShow: 3,
    		slidesToScroll: 1,
    		arrows:false,
    		dots:false,
            autoplay: true,
            autoplaySpeed: 1500,
            pauseOnHover: false,
            // responsive: [
            //     {
            //       breakpoint: 768,
            //       settings: {
            //         slidesToShow: 2
            //       }
            //     },
            //     {
            //       breakpoint: 576,
            //       settings: {
            //         slidesToShow: 1
            //       }
            //     },
            // ]
        };

        const { content } = this.props


        return(
            <section className="description_section partners_block" id="partners">
                <div className="partners_description">
                    <div className="row justify-content-between">
                        <div className="col-md-6 row home__title">
                            <div className="title col-lg-9">{ content.title }</div>
                        </div>
                        <div className="col-md-6">
                            <div className="content" dangerouslySetInnerHTML={{ __html: content.description }}></div>
                        </div>
                    </div>
                </div>


                <div className="my-5">
                    {content.logos.length ? (
                        <Slider {...settings} className="logos_slider">
                            { content.logos.map((logo) => (
                                <div className="one_logos_slide">
                                    <img src={ logo.guid } />
                                </div>
                            ))}
                        </Slider>
                    ) : ''}
                </div>

            </section>
        )
    }
}
