import React, { Component } from 'react'

export default class MainBlock extends Component {

    state = {
    }


    componentDidMount = () => {

    }

    render(){
        const { content } = this.props
        return(
            <section className="white_bg title projects_title" id="portfolio_title">
                <div className="row justify-content-center">
                    <div className="col-md-8">
                        <h2>{ content.title }</h2>
                        <div className="row justify-content-center">
                            <div className="col-lg-8 description">
                                <p>{ content.description }</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
