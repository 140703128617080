import React from 'react';
import ReactDOM from 'react-dom';
import HttpsRedirect from 'react-https-redirect';
import './scss/style.scss';

import App from './App';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
    <React.StrictMode>
        <HttpsRedirect>
            <App />
        </HttpsRedirect>
    </React.StrictMode>,
    document.getElementById('root')
);
