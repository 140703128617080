import React, { Component } from 'react'
import ReactPlayer from 'react-player'
import Trans from '../../helpers/locale/transStr'


export default class MainBlock extends Component {

    state = {
        scrollHeight:0,
        openVideo:false,
        openBigVideo:false,
        playing_video:false
    }


    scrollText = () => {

        const scrollHeight = Math.min( window.pageYOffset, window.innerHeight * 1.2 )
        this.setState({ scrollHeight })

 		document.querySelectorAll('.main_text_line:nth-child(2n) span').forEach(function(div) {
			div.style.right = '-' + window.pageYOffset + 'px'
		});

		document.querySelectorAll('.main_text_line:nth-child(2n+1) span').forEach(function(div) {
			div.style.left = '-' + window.pageYOffset + 'px'
		});

		document.querySelectorAll('.main_text_line .text').forEach(function(div) {
			div.style.opacity = 1 - window.pageYOffset/window.innerHeight
		});


    	var block_width = document.getElementById('main_video_round').offsetWidth;
    	var block_height = document.getElementById('main_video_round').offsetHeight;

    	var right = window.pageYOffset //* ((window.innerWidth - block_width)/(2.4 * window.innerHeight));
    	if ( right > (window.innerWidth - block_width) / 2 ){
    		right = (window.innerWidth - block_width) / 2;
            document.getElementById('main_video_round').classList.add('fixed')
    	} else{
            document.getElementById('main_video_round').classList.remove('fixed')
        }

    	var bottom = 80 - window.pageYOffset //* ((window.innerHeight - block_height)/(2.4 * window.innerHeight)) + 40;
    	// if ( bottom > (window.innerHeight - block_height) / 2 ){
    	// 	bottom = (window.innerHeight - block_height) / 2;
    	// }
    	document.getElementById('main_video_round').style.right = right + 'px';
    	document.getElementById('main_video_round').style.bottom = bottom + 'px';


    	document.getElementById('main_video_round').style.minWidth = '0';
    	document.getElementById('main_video_round').style.minHeight = '0';
    	// document.getElementById('main_video_round').style.inset = 'auto';

    	// document.getElementById('main_video_round').style.borderRadius =  '50%';
    	// document.getElementById('main_video_round').style.position = 'absolute';
    	document.getElementById('main_video_round').style.left = 'auto';
    	document.getElementById('main_video_round').style.top = 'auto';

    	// document.getElementById('main_video_round').style.margin = '0';

        // document.getElementById('main_video_round').style.bottom = -scrollHeight + 40 + 'px';

                    // document.getElementById('main_video_round').style.borderRadius =  '50%';
    	if ( window.pageYOffset > window.innerHeight * 0.7 && window.pageYOffset < window.innerHeight * 4  ){
            document.getElementById('main_video_round').classList.add('open')
        } else{
            document.getElementById('main_video_round').classList.remove('open');
            this.setState({ openVideo:false })
        }


    }

    componentDidMount = () => {
         // window.addEventListener('scroll', this.scrollText);
        // setTimeout(() => {
        //     AOS.init();
        // }, 200)
         // AOS.refresh();

    }

    componentWillUnmount = () => {
        // window.removeEventListener('scroll', this.scrollText);
    }


    render(){

        const { scrollHeight, openVideo, openBigVideo, playing_video } = this.state
        const { content } = this.props
        let zIndex = 100
        if(openBigVideo){
            console.log( openVideo )
            zIndex = 1200
        }

        console.log( content )

        return(
            <>
                <section className="main_section" style={{ paddingBottom:scrollHeight, zIndex}}>
                    <div className="container-fluid">
                        <div className="main_text">
                            <div className="main_text_line"><span class="line1" data-aos="flip-up" data-aos-delay="50">{ content.title1 }</span></div>
                            <div className="main_text_line"><span class="line2" data-aos="flip-up" data-aos-delay="150">{ content.title2 }</span></div>
                            <div className="main_text_line"><span class="line3" data-aos="flip-up" data-aos-delay="250">{ content.title3 }</span></div>
                            <div className="main_text_line">
                                <div className="text">
                                    <div className=" d-none d-lg-block">
                                        <p>{ content.description }</p>
                                    </div>
                                </div>
                                <span class="line4" data-aos="flip-up" data-aos-delay="350">{ content.title4 }</span>
                            </div>
                            <div className="main_text_line d-lg-none">
                                <div className="text mobile_text">
                                    <p>{ content.description }</p>
                                </div>
                            </div>
                        </div>

                            <div className={`video_round  ${openVideo ? 'open_video' : ''}`} id="main_video_round" style={{ backgroundImage: `url('${ content.videoImage.guid }')` }}>

                                { !openVideo ? (
                                    <div className="video_play_button" onClick={() => {
                                        // this.setState({ openVideo:true }, () => {
                                        //     document.getElementById("video_video").play();
                                        //     document.getElementById('main_video_round').classList.add('open');
                                        // })


                                            document.getElementById('big_video_screen').classList.add('active')
                                            document.querySelector('header').classList.add('d-none')
                                            // document.getElementById("big_screen_video").play();
                                            document.body.style.overflow = 'hidden';
                                            this.setState({ playing_video:true })
                                            console.log('333333333')
                                            this.setState({ openBigVideo:true })

                                    }}>
                                        <img src="/images/video_play_triangle.svg" className="play_triangle" />
                                        <img src={ content.videoTextImage.guid } className="round_text" />
                                    </div>
                                ) : (
                                    <div dangerouslySetInnerHTML={{ __html:content.videoIframe}}>
                                    {/*<video controls="controls" autoplay id="video_video">
                                        <source src={ content.video.guid } type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"' />
                                    </video>*/}
                                    </div>

                                )}
                            </div>

                        <div className="video_round_link" onClick={() =>{
                            this.setState({ playing_video:true })
                            document.getElementById('big_video_screen').classList.add('active')
                            document.querySelector('header').classList.add('d-none')
                            console.log('111111')
                            document.body.style.overflow = 'hidden';
                            // // document.querySelector('#big_screen_video iframe').play();
                            // // document.getElementById("big_screen_video").play();
                            // document.querySelector('#big_screen_video iframe').contentWindow.postMessage('{"event":"command","func":"' + 'playVideo' + '","args":""}', '*');

                            this.setState({ openBigVideo:true })
                        }}></div>



                        <a className="portfolio_link" href="#projects_section"><Trans>Смотреть портфолио</Trans></a>
                    </div>
                </section>
                <div className="big_video_screen" id="big_video_screen">
                    <div className="video_cross" onClick={() =>{

                        this.setState({ playing_video:false })
                        document.getElementById('big_video_screen').classList.remove('active');
                        document.body.style.overflow = 'unset';
                        document.querySelector('header').classList.remove('d-none')
                        // // document.querySelector("big_screen_video").pause();
                        // // document.querySelector('#big_screen_video iframe').contentWindow.postMessage('{"event":"command","func":"' + 'pauseVideo' + '","args":""}', '*');
                        // this.setState({ openBigVideo:false })
                    }}>
                        <img src="/images/icons/close.svg" />
                    </div>
                    <ReactPlayer url={content.videoEmbed} playing={playing_video} width="100%" height="100%" />


                    {/*<div dangerouslySetInnerHTML={{ __html:content.videoIframe}} className="big_screen_video" id="big_screen_video"></div>

                    <video width="100%" controls id="big_screen_video">
                      <source src={ content.videoImage.guid } type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>*/}
                </div>
            </>
        )
    }
}
