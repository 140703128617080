import React, { Component } from "react";
import strings from "./strings";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams,
  withRouter
} from "react-router-dom";

class Trans extends Component {
  translate = () => {
    const string = this.props.children;
    let lang = 'ru'
    const { pathname } = this.props.location
    if( pathname.split('/')[1] == 'en' ){
        lang = 'en'
    }

    let translate = "";
    if (string && string != "undefined") {
      if (strings[string] && strings[string] != "undefined") {
        if (lang && lang != "undefined") {
          translate = strings[string][lang];
        } else {
          translate = string;
        }
      } else {
        translate = string;
      }
    }
    return translate;
  };

  render() {
    return this.translate();
  }
}
export default withRouter(Trans);
