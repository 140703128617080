import React, { Component } from 'react'

export default class MainBlock extends Component {

    state = {
        mouseRadius:0,
        yCircle:0,
        xCircle:0,
    }

    scrollMouse = (e) => {
        let mouseRadius = 0;
        if( window.pageYOffset > document.getElementById('about_us').offsetTop + 100 ){
            mouseRadius = Math.max((window.pageYOffset - document.getElementById('about_us').offsetTop - 30) * 2, 0);
        }
        this.setState({ mouseRadius }, () => {
            const start = window.pageYOffset;
            setTimeout(() => {
                const end = window.pageYOffset;
                const distance = end - start;
                let { yCircle } = this.state
                yCircle += distance
                this.setState({ yCircle })
            }, 200);
        });
        document.getElementById('about_us').style.paddingBottom = Math.min(window.innerWidth /4, mouseRadius + 20) + 'px'
    }

    handleMouseMove = (e) => {
        const { clientX, clientY } = e;
        const { mouseRadius } = this.state;
        this.setState({
            xCircle: clientX - mouseRadius,
            yCircle: clientY - mouseRadius/2,
        })
    }

    componentDidMount = () => {
         // window.addEventListener('scroll', this.scrollMouse);
    }

    componentWillUnmount = () => {
        // window.removeEventListener('scroll', this.scrollMouse);
    }

    render(){

        const { xCircle, yCircle, mouseRadius } = this.state;
        const { content } = this.props;


        return(
            <section className="description_section" id="about_us" onMouseMove={e => this.handleMouseMove(e)}>

                <span className="white_mouse_circle" style={{
                      // left: xCircle,
                      // top: yCircle,
                      // position:'fixed',
                      left: window.innerWidth < mouseRadius*2 ? (window.innerWidth - mouseRadius*2 ) : 0,
                      right: window.innerWidth < mouseRadius*2 ? (window.innerWidth - mouseRadius*2 ) : 0,
                      top:mouseRadius,
                      bottom:0,
                      margin:'auto',

                      width:mouseRadius*2 + 'px',
                      height:mouseRadius*2 + 'px',
                    }}></span>


                <div className="description_block row justify-content-between">
                    <div className="col-lg-6 col-sm-11 col-12">
                        <div className="title" data-aos="flip-up" data-aos-delay="50">{ content.title }</div>
                    </div>
                    <div className="col-lg-5 col-12">
                        <div className="content" dangerouslySetInnerHTML={{ __html:content.content}}></div>
                    </div>
                </div>
            </section>
        )
    }
}
