import { Component, Fragment } from 'react'

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams,
  withRouter
} from "react-router-dom";
import {Helmet} from "react-helmet";

import MainBlock from '../blocks/MainBlock'
import DescriptionBlock from '../blocks/DescriptionBlock'
import NumbersBlock from '../blocks/NumbersBlock'
import GoodTitleBlock from '../blocks/GoodTitleBlock'
import ServicesBlock from '../blocks/ServicesBlock'
import PortfolioTitleBlock from '../blocks/PortfolioTitleBlock'
import PortfolioBlock from '../blocks/PortfolioBlock'
import ReviewsBlock from '../blocks/ReviewsBlock'
import PartnersBlock from '../blocks/PartnersBlock'
import FooterTitleBlock from '../blocks/FooterTitleBlock'

import contentAPI from '../../api/contentAPI'

class App extends Component {

    state = {
        blocks:[],
        seo:{
            title:'Cheftech',
            metaDesc:'Cheftech',
        }
    }

    componentDidMount = () => {
        const { lang } = this.props
        contentAPI.getPageContent('', lang).then((data) => {
            const { blocks } = data.data.pages.edges[0].node.blocks
            const { seo } = data.data.pages.edges[0].node
            this.setState({ blocks, seo }, () => {

                if( this.props.location.hash ){
                    const hash = this.props.location.hash.replace('#', '')
                    document.getElementById(hash).scrollIntoView({
                        behavior: 'smooth'
                    });
                } else{
                    window.scrollTo(0, 0);
                }


            })
            this.props.onLoad()
        })
    }

    render(){
        const { blocks, seo } = this.state
        console.log( seo )
        console.log( blocks )
        return (
            <Fragment>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{ seo.title }</title>
                    <meta name="description" content={ seo.metaDesc } />
                </Helmet>

                {blocks.length ? blocks.map((block) => (
                    <Fragment>
                        {
                            block.fieldGroupName == 'Page_Blocks_Blocks_MainBlock' ? (<MainBlock content={block} />) :
                            block.fieldGroupName == 'Page_Blocks_Blocks_AboutUs' ? (<DescriptionBlock content={block} />) :
                            block.fieldGroupName == 'Page_Blocks_Blocks_NumbersBlock' ? (<NumbersBlock content={block} />) :
                            block.fieldGroupName == 'Page_Blocks_Blocks_GoodTitle' ? (<GoodTitleBlock content={block} />) :
                            block.fieldGroupName == 'Page_Blocks_Blocks_ServicesBlock' ? (<ServicesBlock content={block} />) :
                            block.fieldGroupName == 'Page_Blocks_Blocks_OurProjects' ? (
                                <Fragment>
                                    <PortfolioTitleBlock content={block} />
                                    <PortfolioBlock content={block} />
                                </Fragment>
                            ) :
                            block.fieldGroupName == 'Page_Blocks_Blocks_ReviewsBlock' ? (<ReviewsBlock content={block} />) :
                            block.fieldGroupName == 'Page_Blocks_Blocks_Partners' ? (<PartnersBlock content={block} />) :
                            block.fieldGroupName == 'Page_Blocks_Blocks_Description' ? (<FooterTitleBlock content={block} />) : ''
                        }
                    </Fragment>
                )) : ''}
            </Fragment>
        );
    }
}

export default withRouter(App);
