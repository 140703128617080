// import ReactFullpage from '@fullpage/react-fullpage';
// import { FullPage, Slide } from 'react-full-page';
// // import Fullpage, { Sections, Section } from '@ap.cx/react-fullpage'
// import ScrollableContainer from "react-full-page-scroll";
// import {SectionsContainer, Section} from 'react-fullpage';
// import ReactPageScroller from 'react-page-scroller';
//
// import "fullpage.js/vendors/scrolloverflow"; // Optional. When using scrollOverflow:true
// import ReactFullpage from "@fullpage/react-fullpage";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useRouteMatch,
  useParams
} from "react-router-dom";
import { useEffect, useRef, useState, Fragment } from 'react';
// import { Redirect } from "react-router"

import Header from './components/header/Header'
import Footer from './components/footer/Footer'
import Preloader from './components/preloader/Preloader'
import contentAPI from './api/contentAPI'

import Main from './components/pages/Main'
import Case from './components/pages/Case'

function App(props) {

    const [ socialLinks, setSocialLinks ] =  useState({
        facebookLink:'',
        instagramLink:'',
        linkedinLink:'',
        youtubeLink:'',
        telegramLink:'',
        whatsappLink:''
    });


    const [isLoaded, setIsLoaded] = useState(false)


    useEffect(() => {
        contentAPI.themeSettings().then((data) => {
            const { themeSettings } = data.data.themeSettings
            const { facebookLink, instagramLink, linkedinLink, youtubeLink, telegramLink, whatsappLink } = themeSettings
            setSocialLinks({ facebookLink, instagramLink, linkedinLink, youtubeLink, telegramLink, whatsappLink })
        })
    }, [])



    return (
        <main className="App">
            <Preloader isLoaded={isLoaded} />
            <Router>
                <Header socialLinks={socialLinks} />
                <div className="black_bg"></div>
                <span id="white_mouse_circle" className="white_mouse_circle"></span>

                    <Switch>
                        <Route exact path="/ru">
                            <Main {...props} lang="ru" onLoad={() => { setIsLoaded(true) }} />
                        </Route>
                        <Route exact path="/en">
                            <Main {...props} lang="en" onLoad={() => { setIsLoaded(true) }} />
                        </Route>
                        <Route exact path='/:slug'>
                            <Case {...props} lang="ru" onLoad={() => { setIsLoaded(true) }} />
                        </Route>
                        <Route exact path='/:lang/:slug'>
                            <Case {...props} onLoad={() => { setIsLoaded(true) }} />
                        </Route>

                        <Route path="/">
                            <Main lang="ru" onLoad={() => { setIsLoaded(true) }} />
                        </Route>
                    </Switch>
                <Footer socialLinks={socialLinks} />
            </Router>
        </main>
    );
}

export default App;
