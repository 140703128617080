import React, { Component } from 'react'

export default class MainBlock extends Component {

    state = {
        currentSlide:0,
        hoverSlide:0,
        sliderHeight:'520',
    }

    slideHover = (hoverSlide) => {
        this.setState({ hoverSlide })
    }

    slideUnHover = () => {
        this.setState({ hoverSlide:0 })
    }

    slideClick = (currentSlide) => {
        if( currentSlide >= 0 && currentSlide < document.querySelectorAll('.reviews_slider .one_review_slide').length ){
            this.setState({ currentSlide }, () => {

            })
            const currentSlideObj = document.querySelectorAll('.reviews_slider .one_review_slide')[currentSlide]
            if( currentSlideObj ){
                currentSlideObj.classList.add('transition')
            }

            setTimeout(() =>{
                if( document.querySelector('.reviews_slider .one_review_slide.active.current.transition') ){
                    document.querySelector('.reviews_slider .one_review_slide.active.current.transition').classList.remove('transition')
                }
            }, 700)
        }
    }

    componentDidMount = () => {
        let sliderHeight = 0;
        [...document.querySelectorAll('.one_review_slide')].map((slide) => {
            sliderHeight = Math.max(sliderHeight, slide.offsetHeight)
        })
        sliderHeight += 10;
        this.setState({ sliderHeight })


        window.addEventListener("resize", () => {
            let sliderHeight = 0;
            [...document.querySelectorAll('.one_review_slide')].map((slide) => {
                sliderHeight = Math.max(sliderHeight, slide.offsetHeight)
            })
            sliderHeight += 10;
            this.setState({ sliderHeight })
        });
    }

    render(){

        const { currentSlide, sliderHeight, hoverSlide } = this.state
        const { content } = this.props
        return(
            <section>
                <div className="container-fluid">
                    <div className="reviews_block">
                        <h2>{ content.title }</h2>
                        <div className="my-5">
                            <div className="reviews_slider"  style={{ height: sliderHeight  + 'px'}}>
                                <div className="reviews_slides"  style={{ height: sliderHeight + 'px' }}>

                                    { content.reviews.length ? content.reviews.map((review, i) => (

                                        <div className={`one_review_slide
                                            ${
                                                i < currentSlide ? 'past' :
                                                i == currentSlide ? 'active current' :
                                                i == currentSlide +1 ? 'active second' :
                                                i == currentSlide +2 ? 'active' :
                                                i > currentSlide +2 ? 'future' : ''

                                            }
                                            ${ i < hoverSlide && i >= currentSlide ? 'hover' : '' }`}
                                            style={{ height: sliderHeight + 'px'}}
                                            onMouseEnter={() => { this.slideHover(i) }}
                                            onMouseLeave={() => { this.slideUnHover(i) }}
                                            onClick={() => { this.slideClick(i) }}
                                            >
                                            <div className="row">
                                                <div className="col-lg-9">
                                                    <div className="review_text" dangerouslySetInnerHTML={{ __html: review.text }}></div>

                                                    <div className="review_author d-flex">
                                                        <div className="review_author_image_block mr-3">
                                                            <div className="review_author_image" style={{ backgroundImage: review.avatar ? "url('" + review.avatar.guid + "')" : '' }}></div>
                                                        </div>
                                                        <div className="review_author_content">
                                                            <div className="review_author_title">{ review.name }</div>
                                                            <div className="review_author_description">{ review.position }</div>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="col-lg-3 volvo">
                                                    <img src={ review.logo ? review.logo.guid : '' } className="w-100" />
                                                </div>
                                            </div>
                                        </div>
                                    )) : ''}
                                </div>


                                <div className="reviews_slider_arrows">
                                    <div className="one_arrow left"
                                    onClick={() => { this.slideClick(currentSlide-1) }}></div>
                                    <div className="one_arrow right"
                                        onMouseEnter={() => { this.slideHover(currentSlide+1) }}
                                        onMouseLeave={() => { this.slideUnHover() }}
                                        onClick={() => { this.slideClick(currentSlide+1) }}
                                    ></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
