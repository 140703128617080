import React from 'react'
import Lottie from 'react-lottie';
import animationData from '../../assets/images/preloader_lottie.json'

// import AOS from 'aos';


class Preloader extends React.Component {


    state = {
        isStopped: false,
        hidden: false,
        display:true
    }

    hideAnimation = (e) => {
        setTimeout(() => {
            this.setState({ isStopped:true});
            //
            // this.setState({ isStopped:true, hidden:true }, () => {
            //     setTimeout(() => {
            //         this.setState({ display:false })
            //     }, 1000)
            // })

            // AOS.init();
            // AOS.init();
        }, 1800)
    }

    componentDidUpdate = (prevProps, prevState) => {
        if( ( this.props.isLoaded && !prevProps.isLoaded && this.state.isStopped ) ||
            ( this.state.isStopped && !prevState.isStopped && this.props.isLoaded ) ){

            this.setState({ hidden:true }, () => {
                setTimeout(() => {
                    this.setState({ display:false })
                }, 1000)
            })
        }
    }

    render() {

        const { hidden, display } = this.state
        const buttonStyle = {
          display: 'block',
          margin: '10px auto'
        };

        const defaultOptions = {
          loop: true,
          autoplay: true,
          animationData: animationData,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        },
                  height:400,
                  width:400
        };

        return (
            <div className={`preloader ${ hidden ? 'hidden' : '' } ${ !display ? 'd-none' : '' }`}>
                <Lottie options={defaultOptions}
                    isStopped={this.state.isStopped}
                    isPaused={false}
                    eventListeners={[
                        {
                            eventName: 'loopComplete',
                            callback: (e) => this.hideAnimation(e),
                        },
                        {
                            eventName: 'DOMLoaded',
                            callback: (e) => this.hideAnimation(e),
                        },

                    ]}
                />
            </div>
        )
    }
}

export default Preloader;
