import { useEffect, useRef, useState } from 'react';
import { ContentsWrapper, getWeightByProgress, useScrollProgress } from 'framed-animation-on-scroll';
import contentAPI from '../../api/contentAPI'
import Trans from '../../helpers/locale/transStr'

import { withRouter } from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

function PortfolioBlock( props ) {


    const [blockHeight, setBlockHeight] = useState('auto');
    const [portfolio, setPortfolio] = useState([]);
    const [isLoaded, setIsLoaded] = useState(0);

    const { pathname } =  props.location
    let lang = 'ru'
    if( pathname.split('/')[1] == 'en' ){
        lang = 'en'
    }
      useEffect(() => {
          contentAPI.getPortfolio(lang).then(({ data }) => {
              const projects = []
              data.portfolio.edges.map(({ node }) => {
                  if( node.featuredImage ){
                      projects.push({
                          title:node.title,
                          image:node.featuredImage.node.mediaItemUrl,
                          description:node.excerpt,
                          slug:node.slug
                      })

                  }
              })
              setPortfolio(projects)
              if ( window.innerWidth >= 768 ){
                  setBlockHeight(projects.length * 110 + 'vh');
              } else{

                  // const textHeight = document.getElementById('#projects_section').offsetHeight
                  let textHeight = 0;
                  [...document.querySelectorAll(".one_project_content")].map((div) => {
                      div.classList.add('active')
                      textHeight += div.offsetHeight
                  })
                  setBlockHeight( textHeight + 'px')


              }
          })
          setIsLoaded(1)
      }, [])





  const ref = useRef();
  const [progress] = useScrollProgress(ref, 0);

  const [projectNumber, setProjectNumber] = useState(0);
  const [currentWidth, setCurrentWidth] = useState(60);
  const [nextWidth, setNextWidth] = useState(30);
  const [nextLeft, setNextLeft] = useState(90);
  // const [blockHeight, setBlockHeight] = useState(90);



  // const [blockHeight, setBlockHeight] = useState(portfolio_length * 110 + 'vh');

  useEffect(() => {

    if ( window.innerWidth >= 768 ){
        if( progress > 0 && progress < 100){
            const portfolio_length = portfolio.length
            document.getElementById('projects_content').style.top = - progress * portfolio_length + 'vh';

            // progress / ( 100 / portfolio_length ) * 0.7
            // progress / ( 100 / portfolio_length ) * 0.7 + Math.floor(progress / portfolio_length)
            // const percent = ( 100 / portfolio_length ) * 0.7 + Math.floor(progress / portfolio_length)
            // projectNumber
            //
            // if( percent > projectNumber * 20 + 14){
            //     setProjectNumber(projectNumber + 1)
            // } else if( percent > projectNumber * 20 + 14 ){
            //
            // }
            let project_number = 0
            if( portfolio_length ){
                project_number = Math.min(Math.max(Math.floor( progress / ( 100 / portfolio_length ) ), 0), portfolio_length) //Math.min(Math.max(Math.ceil((progress - ( 100 / portfolio_length ) * 0.7) / Math.ceil(progress / portfolio_length)), 0), portfolio_length)
            }


            // const project_number = Math.ceil(progress / (90 / portfolio_length)) - 1
            const percent = (progress / (100 / portfolio_length)) - Math.floor(progress / (100 / portfolio_length))
            // project_number = Math.min(Math.max( percent / ( 1 / portfolio_length ), 0), portfolio_length)
            // const project_number = Math.ceil(( 100 / portfolio_length ) * 0.7 + progress / portfolio_length) - 1
            // const project_number = Math.ceil(progress / (100 / (portfolio_length+1))) - 1
            // console.log( progress )
            // console.log( progress - ( 100 / portfolio_length ) * 0.7 )
            // console.log( Math.ceil(progress / portfolio_length) )
            // console.log( project_number )
            // const percent = (progress / (100 / portfolio_length)) - Math.ceil(progress / (100 / portfolio_length))

            setProjectNumber(project_number)
            setCurrentWidth(Math.min(Math.max(70 - percent * 70, 0), 70))
            setNextWidth(Math.min(Math.max(20 + percent * 80, 0), 70))
            setNextLeft((1-percent)*100)


                    if ( window.innerWidth < 768 && progress < 90 ){
                        // document.getElementById("projects_slider").style.position = 'fixed'
                        document.getElementById("projects_slider").classList.add('fixed')
                    }

        } else {
            document.getElementById("projects_slider").style.position = 'relative'
            document.getElementById("projects_slider").classList.remove('fixed')
        }
    } else {
        //

        if( document.getElementById('#projects_section') ){
            // const textHeight = document.getElementById('#projects_section').offsetHeight
            let textHeight = 0;
            [...document.querySelectorAll(".one_project_content")].map((div) => {
                div.classList.add('active')
                textHeight += div.offsetHeight
            })
            setBlockHeight( textHeight + 'px')
        }
    }


}, [progress, isLoaded])

  const imageHeight = window.innerWidth >= 768 && blockHeight == 'auto' ? '110vh' : ''


  return (

      <ContentsWrapper
        ref={ref}
        height={ blockHeight }
        bgColor={'white'}
      >
          <section className="white_bg title animation_sticky_bg projects_section" id="projects_section">
              <div className="projects_slider row animation_sticky" id="projects_slider">
                  <div className="projects_slider_numbers_block col-md-1">
                    { portfolio.length ? portfolio.map((project, i) => (
                        <div className={`projects_slider_number ${ projectNumber == i ? 'active' : '' }`}>{ ('0' + parseFloat(i+1)).slice(-2) }</div>
                    )) : '' }
                  </div>

                   <div className="projects_content col-md-6" id="projects_content">
                       { portfolio.length ? portfolio.map((project, i) => (
                           <div className={`one_project_content ${ projectNumber == i ? 'active' : '' }`}>

                               <div className="one_project_title">{ project.title }</div>
                               { project.image ? (
                                   <div className="d-md-none text-center mb-3 one_project_image">
                                   <img className="w-100" src={ project.image } alt={ project.title } />
                                   </div>
                               ) : ''}
                               <div className="one_project_description" dangerouslySetInnerHTML={{ __html: project.description }}></div>
                               <div className="one_project_link_block">
                                   <a href={ lang == 'ru' ? `/${project.slug}/` : `/${lang}/${project.slug}/` } className="one_project_link"><Trans>Смотреть проект</Trans></a>
                               </div>
                           </div>
                       )) : '' }
                   </div>
                   <div class="projects_slider_images_block col-md-5">
                        <div class="projects_slider_images">
                            { portfolio.length ? portfolio.map((project, i) => (
                                <div class={`projects_slider_image ${ projectNumber == i ? 'current' : projectNumber + 1 == i ? 'next' : '' }`}
                                    style={{
                                        width: (projectNumber == i ? currentWidth : projectNumber + 1 == i ? nextWidth : '0') + '%',
                                        left: (projectNumber + 1 == i ? nextLeft : 0) + '%'
                                    }}>
                                    <img className="w-100" src={ project.image } alt={ project.title } />
                                </div>
                            )) : '' }
                        </div>
                    </div>

              </div>
          </section>
      </ContentsWrapper>

  );
}

export default withRouter(PortfolioBlock);
