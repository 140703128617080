import React, { Component } from 'react'

export default class MainBlock extends Component {

    state = {
        counted:[]
    }



    updateCounter = (counter, i) => {
        let count = parseFloat(counter.innerHTML);
        const step = counter.getAttribute("data-step") ? parseFloat(counter.getAttribute("data-step")) : 1;
        const countTarget = parseFloat(counter.getAttribute("data-counttarget"));
        count += step;
        if (count < countTarget) {
            counter.innerHTML = Math.round(count * 100) / 100;
            setTimeout(this.updateCounter, 2000*step/countTarget, counter, i);
            console.log(countTarget)
            console.log(2000/countTarget)
        } else {
            counter.innerHTML = Math.round(countTarget * 100) / 100;
            const { counted } = this.state
            counted.push(i)
            this.setState({counted})
        }
    };

    componentDidMount = () => {
        window.addEventListener('scroll', () => {
            const counters = document.querySelectorAll(".counters");
            const top = counters[0].getBoundingClientRect().top;
            counters.forEach((counter, i) => {
                const { counted } = this.state
                if( !counted.includes(i) && counter.getBoundingClientRect().top < window.innerHeight * 7/8 ){
                    this.updateCounter(counter, i);
                }
            });
        }, true);
    }

    render(){
        const { content } = this.props

        return(
            <section className="white_bg numbers_section" id="numbers_section">
                <div className="container-fluid">
                    <div className="numbers_block">
                        {content.numbers && content.numbers.length ? content.numbers.map(({numberNumber, numberBefore, numberAfter, title, description, numberStep}, i) => (
                            <div className="one_number_block">
                                <div className="one_number">
                                    <div className="number">
                                        <span>{numberBefore}</span>
                                        <span className="counters" id={`counter_number_${i}`} data-counttarget={ numberNumber } data-step={numberStep}>0</span>
                                        <span>{numberAfter}</span>
                                    </div>
                                    <div className="content">
                                        <div className="title">{ title }</div>
                                        <div className="description">
                                            <p>{ description }</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )) : '' }
                    </div>
                </div>
            </section>
        )
    }
}
