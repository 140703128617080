import React, { Component } from 'react'

export default class Header extends Component {

    state = {
    }


    componentDidMount = () => {

    }

    render(){

        const { socialLinks } = this.props

        return(
            <footer className="white_bg">
                <div className="container-fluid">
                    <div className="social_links d-flex">
                    { socialLinks.telegramLink ? (
                        <a href={ socialLinks.telegramLink } className="social_link telegram"><span>Telegram</span></a>
                    ) : '' }
                    { socialLinks.viberLink ? (
                        <a href={ socialLinks.viberLink } className="social_link viber"><span>Viber</span></a>
                    ) : '' }
                    { socialLinks.whatsappLink ? (
                        <a href={ socialLinks.whatsappLink } className="social_link whatsapp"><span>Whatsapp</span></a>
                    ) : '' }
                    </div>
                    <div className="d-flex justify-content-sm-end">
                        <div className="footer_contacts">
                            <div className="footer_contacts_one">info@cheftech.ru</div>
                            <div className="footer_contacts_one">+7 915 399 11 17</div>
                        </div>
                    </div>

                    <div className="d-sm-flex justify-content-between footer_bottom">
                        <div className="copyright">Все права сохранены © 2021 Cheftech</div>
                    </div>
                </div>
            </footer>
        )
    }
}
