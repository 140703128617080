import React, { Component } from 'react'

export default class MainBlock extends Component {

    state = {
        good_title_right_left:100,
        scale:1
    }
    //
    //
    // scrollText = () => {
    //     // console.log( document.getElementById('good_title_left').getBoundingClientRect() )
    //     const scrolledElTop = document.getElementById('good_title_left').getBoundingClientRect().top
    //     const elHeight = document.getElementById('good_title_left').getBoundingClientRect().height
    //     if( scrolledElTop < window.innerHeight * 2 ){
    //         // console.log('11111111111111111')
    //     	const good_top_position = scrolledElTop + elHeight + 50  //document.getElementById('good_title_left').offsetTop + document.getElementById('good_title_left').offsetHeight + 50;
    //     	const good_half_position = elHeight + good_top_position + window.innerHeight;
    //     	const good_title_percent = (scrolledElTop - window.innerHeight * 1/4) * 100 / (window.innerHeight) //(window.pageYOffset + window.innerHeight - good_top_position) * 100 / (good_top_position - good_half_position)
    //         const good_title_right_left = Math.min(Math.max(good_title_percent, 0), 100)
    //
    //
    //         this.setState({ good_title_right_left })
    //     	// document.getElementById('good_title_right').style.left = good_title_right_left + '%'
    //     	// document.getElementById('good_title_left').style.right = good_title_right_left + '%'
    //     	let scale_var = -good_title_percent; //(window.pageYOffset + window.innerHeight / 2 - good_top_position - document.getElementById('good_title_left').offsetHeight) / 2;
    //         let scale = Math.max(scale_var, 1)
    //         this.setState({ scale })
    //
    //         if( scrolledElTop > 0 ){
    //             document.getElementById('good_title').style.paddingBottom = Math.max( -good_title_percent * 8, window.innerHeight * 3/4 ) + 'px'
    //         } else {
    //             document.getElementById('good_title').style.paddingBottom = window.innerHeight * 2 + 'px'
    //         }
    //         if( scale > 1 ){
    //             document.getElementById('good_title_right').style.left = scale + '%'
    //         }
    //
    //
    //         document.getElementById('good_title_right').style.marginTop = '0';
    //         document.getElementById('good_title_right').style.marginLeft = '0';
    //
    //         // console.log( good_title_right_left )
    //     	if( good_title_right_left == 0 && scale < 1000 ){
    //     		var opacity = Math.min(Math.max((1000 - scale) / 1000, 0), 1)
    //     		if( scale > 200 && scale < 1000 && opacity > 0){
    //     			document.getElementById('good_title_right').style.opacity = opacity ? opacity < 0.5 : 1
    //     			document.getElementById('good_title_right').style.position = 'fixed'
    //     			document.getElementById('good_title_right').style.top = '50vh';
    //     		} else {
    //                 if (scale > 1000 ){
    //             		document.getElementById('good_title_right').style.opacity = opacity ? opacity < 0.5 : 1
    //                 }
    //     			document.getElementById('good_title_right').style.position = 'relative'
    //     			document.getElementById('good_title_right').style.opacity = 1
    //     			document.getElementById('good_title_right').style.top = '0';
    //
    //         		document.getElementById('good_title_right').style.opacity = opacity ? opacity < 0.5 : 1
    //                 document.getElementById('good_title_right').style.marginTop =  good_title_percent *100 + 'px';
    //                 document.getElementById('good_title_right').style.marginLeft = good_title_percent *160 + 'px';
    //
    //     		}
    //             // document.getElementById('good_title_right').style.transform = 'scale(' + Math.max(scale, 1) + ')';
    //             document.getElementById('good_title_right').style.fontSize = 140 * Math.max(scale, 1) + 'px';
    //     	} else{
    //             // document.getElementById('good_title_right').style.transform = 'scale(1)';
    //             // document.getElementById('good_title_right').style.top = good_title_percent *105 + 'px';
    //             document.getElementById('good_title_right').style.fontSize = 140 + 'px';
    //     		document.getElementById('good_title_right').style.position = 'relative'
    //     		document.getElementById('good_title_right').style.opacity = 1
    //     		document.getElementById('good_title_right').style.top = '0';
    //     		if( scale > 500 ){
    //     			document.querySelectorAll('.good_title').forEach(function(div) {
    //     				div.style.paddingBottom = window.innerHeight + (window.innerHeight - div.offsetHeight) / 2 + 'px'
    //     			});
    //     		}
    //     	}
    //     }
    //     if( scrolledElTop > window.innerHeight / 2 && scrolledElTop < window.innerHeight ){
    //
    //     }
    // }



    componentDidMount = () => {
        this.setState({ good_title_right_left:0 })
         // window.addEventListener('scroll', this.scrollText);
    }

    componentWillUnmount = () => {
        // window.removeEventListener('scroll', this.scrollText);
    }


    render(){

        const { good_title_right_left } = this.state
        const { content } = this.props
        // console.log('1212121212121')
        return(
            <section className="black_bg_block title" id="good_title_section" ref={(el) => this.instance = el }>
                <div className="row justify-content-center position-relative">
                        <h2 className="good_title" id="good_title">
                            <div id="good_title_left" style={{ right:good_title_right_left + '%' }}><span>{ content.title1 }</span></div>
                            <div id="good_title_right" style={{ left:good_title_right_left + '%' }}><span id="good_title_right_span">{ content.title2 }</span></div>
                        </h2>
                </div>
            </section>
        )
    }
}
