import React, { Component, Fragment } from 'react'

export default class MainBlock extends Component {

    state = {
    }



    render(){


        return(
            <Fragment>
                <section class="page_title_section">
                    <div class="container-fluid">
                        <div class="page_title_block">
                            <h1 class="page_title">11 Mirrors Rooftop Restaurant</h1>
                            <div class="page_title_image_block">
                                <img class="w-100" src="assets/images/case-header-image.jpg" />
                                <div class="page_title_image_arrow"></div>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="white_bg page_content_section">
                    <div class="container-fluid">
                        <div class="page_content">
                            <div class="page_content_title">
                                <h2>О проекте</h2>
                            </div>
                            <div class="page_content_content">
                                <p>Мы заботимся о том, чтобы найти лучшее технологическое оборудование по оптимальной цене,
                                    качеству и срокам поставки. Из опыта мы знаем, где дешевле или как это сделать,
                                    не в ущерб качеству. Сумеем снизить смету от 20 до 30% на поставку техники и ее монтажа.
                                    В этом наша сила. Поэтому с нами и работают лучшие рестораторы и архитекторы.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="page_image_section">
                    <img src="assets/images/case-header-image.jpg" />
                </section>

                <section class="white_bg page_content_section">
                    <div class="container-fluid">
                        <div class="page_content">
                            <div class="page_content_content">
                                <p>Мы заботимся о том, чтобы найти лучшее технологическое оборудование по оптимальной цене,
                                    качеству и срокам поставки. Из опыта мы знаем, где дешевле или как это сделать,
                                    не в ущерб качеству. Сумеем снизить смету от 20 до 30% на поставку техники и ее монтажа.
                                    В этом наша сила. Поэтому с нами и работают лучшие рестораторы и архитекторы.
                                </p>
                                <p>Мы заботимся о том, чтобы найти лучшее технологическое оборудование по оптимальной цене,
                                    качеству и срокам поставки. Из опыта мы знаем, где дешевле или как это сделать,
                                    не в ущерб качеству. Сумеем снизить смету от 20 до 30% на поставку техники и ее монтажа.
                                    В этом наша сила. Поэтому с нами и работают лучшие рестораторы и архитекторы.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="white_bg page_video_section">
                    <div class="container-fluid">
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/YykjpeuMNEk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                </section>

                <section class="white_bg page_content_section">
                    <div class="container-fluid">
                        <div class="page_content">
                            <div class="page_content_content">
                                <p>Мы заботимся о том, чтобы найти лучшее технологическое оборудование по оптимальной цене,
                                    качеству и срокам поставки. Из опыта мы знаем, где дешевле или как это сделать,
                                    не в ущерб качеству. Сумеем снизить смету от 20 до 30% на поставку техники и ее монтажа.
                                    В этом наша сила. Поэтому с нами и работают лучшие рестораторы и архитекторы.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="white_bg page_image_section">
                    <img src="assets/images/case-header-image.jpg" />
                </section>

                <section class="white_bg page_images_section">
                    <div class="container-fluid">
                        <div class="page_images">
                            <div class="row">
                                <div class="col-6">
                                    <img src="assets/images/case-header-image.jpg" class="w-100" />
                                </div>
                                <div class="col-6">
                                    <img src="assets/images/case-header-image.jpg" class="w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>



                <section class="white_bg page_content_section">
                    <div class="container-fluid">
                        <div class="page_content">
                            <div class="page_content_title">
                                <h3>Благодаря аудиту и правильному подбору оборудования мы сократили смету на 30%</h3>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="white_bg page_image_section">
                    <img src="assets/images/case-header-image.jpg" />
                </section>


                <section class="white_bg page_review_section">
                    <div class="container">
                        <div class="page_content">
                            <div class="page_content_title text-center">
                                <h2>Что говорит клиент</h2>
                            </div>
                        </div>
                        <div class="page_review">
                            <div class="row">
                                <div class="col-9">
                                    <div class="review_text">
                                        <p>Мы работаем с Кириллом и командой Cheftech уже 7 лет и вместе сделали более 12 ресторанов. Кирилл знает о кухонном оборудовании и его проектировании очень много и знает где его можно достать по лучшей цене/качеству</p>
                                    </div>
                                    <div class="review_author d-flex">
                                        <div class="review_author_image_block mr-3">
                                            <div class="review_author_image" style="background-image:url('assets/images/reviewer.jpg')"></div>
                                        </div>
                                        <div class="review_author_content">
                                            <div class="review_author_title">Дима Борисов</div>
                                            <div class="review_author_description">Ресторатор в “Итальянская кухня”</div>
                                        </div>

                                    </div>
                                </div>
                                <div class="col-3 volvo">
                                    <img src="assets/images/volvo.svg" class="w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <section class="page_next_case">
                    <div class="container">
                        <div class="next_case_title">
                            <div class="next_case_title_top">
                                Следующий проект
                            </div>
                            <h2>Арбат</h2>
                        </div>

                        <div class="next_case_image">
                            <div class="next_case_image_image">
                                <img class="w-100" src="assets/images/case-header-image.jpg" />
                            </div>
                            <div class="next_case_image_button">
                                <img src="assets/images/next_case_button.svg" />
                            </div>
                        </div>
                    </div>
                </section>
            </Fragment>
        )
    }
}
