import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import Menu from '../menu/Menu'

// import AOS from 'aos';
import { Link } from "react-router-dom";


class Header extends Component {

    state = {
        open_menu:false,
        header_border:true,
    }

    closeMenu = () => {
        this.setState({ open_menu:false })
    }

    scrollHeader = () => {
        if( window.pageYOffset > 5 ){
            this.setState({ header_border:false })
        } else{
            this.setState({ header_border:true })
        }
    }

    componentDidMount = () => {
         window.addEventListener('scroll', this.scrollHeader);
    }

    componentWillUnmount = () => {
        window.removeEventListener('scroll', this.scrollHeader);
    }


    render(){
        const { open_menu, header_border } = this.state
        const { socialLinks } = this.props
        let lang = 'ru'
        if ( this.props.location.pathname.includes('/en') ){
            lang = 'en'
        }

        return(
            <Fragment>
                <header>
                    <div className="container-fluid p-0">
                        <div className={`header_container ${header_border ? '' : 'no-border' }`}>
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="header_logo">
                                    <a href="/" onClick={() => {
                                        window.scrollTo({
                                          top: 0,
                                          behavior: 'smooth'
                                        });
                                    }}>
                                        <img src="/images/logo_white.svg" />
                                    </a>
                                </div>
                                <div className="header_right d-flex">
                                    <div className="header_langs">
                                        <a className="header_langs_button header_round_button" href={lang == 'ru' ? '/en' : '/ru'}>{lang == 'ru' ? 'EN' : 'RU'}</a>
                                    </div>
                                    <div className={`header_menu_button header_round_button ${open_menu ? 'close' : ''}`} onClick={() => {
                                        this.setState({ open_menu: !open_menu});

                                        // [...document.querySelectorAll('.menu__title-item')].map((link) => {
                                        //     link.classList.remove('aos-animate');
                                        //     link.classList.remove('aos-init');
                                        //     link.classList.add('aos-animate');
                                        //     link.classList.add('aos-init');
                                        //     console.log(link)
                                        // })
                                    }}></div>
                                </div>
                            </div>
                        </div>

                        <ul className="menu__list menu__list-hero header_social">
                            { socialLinks.facebookLink ? (
                                <li className="menu__list-item menu__list-hero-item">
                                        <a href={socialLinks.facebookLink} target="_blank">
                                            <span>Facebook</span>
                                            <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M3.32419 0.423058H10.1236C10.3956 0.423058 10.5769 0.609366 10.5769 0.88883V7.87542C10.5769 8.15489 10.3956 8.34119 10.1236 8.34119C9.85167 8.34119 9.67035 8.15489 9.67035 7.87542V2.00669L1.05771 10.5769L0.423096 9.92482L9.03573 1.44776H3.32419C3.05222 1.3546 2.8709 1.16829 2.8709 0.88883C2.8709 0.609366 3.05222 0.423058 3.32419 0.423058Z" fill="white"/>
                                            </svg>
                                        </a>
                                </li>
                            ) : '' }
                            { socialLinks.instagramLink ? (
                                <li className="menu__list-item menu__list-hero-item">
                                    <a href={socialLinks.instagramLink} target="_blank">
                                        <span>Instagram</span>
                                        <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M3.32419 0.423058H10.1236C10.3956 0.423058 10.5769 0.609366 10.5769 0.88883V7.87542C10.5769 8.15489 10.3956 8.34119 10.1236 8.34119C9.85167 8.34119 9.67035 8.15489 9.67035 7.87542V2.00669L1.05771 10.5769L0.423096 9.92482L9.03573 1.44776H3.32419C3.05222 1.3546 2.8709 1.16829 2.8709 0.88883C2.8709 0.609366 3.05222 0.423058 3.32419 0.423058Z" fill="white"/>
                                        </svg>
                                    </a>
                                </li>
                            ) : '' }
                            { socialLinks.linkedinLink ? (
                                <li className="menu__list-item menu__list-hero-item">
                                    <a href={socialLinks.linkedinLink} target="_blank">
                                        <span>LinkedIn</span>
                                        <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M3.32419 0.423058H10.1236C10.3956 0.423058 10.5769 0.609366 10.5769 0.88883V7.87542C10.5769 8.15489 10.3956 8.34119 10.1236 8.34119C9.85167 8.34119 9.67035 8.15489 9.67035 7.87542V2.00669L1.05771 10.5769L0.423096 9.92482L9.03573 1.44776H3.32419C3.05222 1.3546 2.8709 1.16829 2.8709 0.88883C2.8709 0.609366 3.05222 0.423058 3.32419 0.423058Z" fill="white"/>
                                        </svg>
                                    </a>
                                </li>
                            ) : '' }
                            { socialLinks.youtubeLink ? (
                                <li className="menu__list-item menu__list-hero-item">
                                    <a href={socialLinks.youtubeLink} target="_blank">
                                        <span>Youtube</span>
                                        <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M3.32419 0.423058H10.1236C10.3956 0.423058 10.5769 0.609366 10.5769 0.88883V7.87542C10.5769 8.15489 10.3956 8.34119 10.1236 8.34119C9.85167 8.34119 9.67035 8.15489 9.67035 7.87542V2.00669L1.05771 10.5769L0.423096 9.92482L9.03573 1.44776H3.32419C3.05222 1.3546 2.8709 1.16829 2.8709 0.88883C2.8709 0.609366 3.05222 0.423058 3.32419 0.423058Z" fill="white"/>
                                        </svg>
                                    </a>
                                </li>
                            ) : '' }
                            { socialLinks.telegramLink ? (
                                <li className="menu__list-item menu__list-hero-item">
                                    <a href={socialLinks.telegramLink} target="_blank">
                                        <span>Telegram</span>
                                        <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M3.32419 0.423058H10.1236C10.3956 0.423058 10.5769 0.609366 10.5769 0.88883V7.87542C10.5769 8.15489 10.3956 8.34119 10.1236 8.34119C9.85167 8.34119 9.67035 8.15489 9.67035 7.87542V2.00669L1.05771 10.5769L0.423096 9.92482L9.03573 1.44776H3.32419C3.05222 1.3546 2.8709 1.16829 2.8709 0.88883C2.8709 0.609366 3.05222 0.423058 3.32419 0.423058Z" fill="white"/>
                                        </svg>
                                    </a>
                                </li>
                            ) : '' }
                            { socialLinks.whatsappLink ? (
                                <li className="menu__list-item menu__list-hero-item">
                                    <a href={socialLinks.whatsappLink} target="_blank">
                                        <span>WhatsApp</span>
                                        <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M3.32419 0.423058H10.1236C10.3956 0.423058 10.5769 0.609366 10.5769 0.88883V7.87542C10.5769 8.15489 10.3956 8.34119 10.1236 8.34119C9.85167 8.34119 9.67035 8.15489 9.67035 7.87542V2.00669L1.05771 10.5769L0.423096 9.92482L9.03573 1.44776H3.32419C3.05222 1.3546 2.8709 1.16829 2.8709 0.88883C2.8709 0.609366 3.05222 0.423058 3.32419 0.423058Z" fill="white"/>
                                        </svg>
                                    </a>
                                </li>
                            ) : '' }
                        </ul>

                    </div>
                </header>
                <Menu open={open_menu} closeMenu={this.closeMenu} socialLinks={socialLinks} />
            </Fragment>
        )
    }
}

export default withRouter(Header)
